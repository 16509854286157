@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Floating label styles */
.float-label {
  position: relative;
}
.float-label label {
  position: absolute;
  left: 1rem;
  top: 0.75rem;
  transition: all 0.2s ease;
  background-color: white;
  padding: 0 0.25rem;
  pointer-events: none;
}
.peer-placeholder-shown\:top-3 {
  top: 0.75rem;
}
.peer-focus:-top-2 {
  top: -0.5rem;
  font-size: 0.75rem;
  color: #3b82f6; /* blue-500 */
}


* {
  scroll-behavior: smooth;
}

body {
  background-color: #fff7eb;
  text-decoration-skip-ink: none;
}

@keyframes floatAnimation {
  0%,
  100% {
    transform: translate(-25%, -25%) translateX(0px) translateY(0px);
  }
  25% {
    transform: translate(-25%, -25%) translateX(10px) translateY(-10px);
  }
  50% {
    transform: translate(-25%, -25%) translateX(-10px) translateY(10px);
  }
  75% {
    transform: translate(-25%, -25%) translateX(10px) translateY(-10px);
  }
}

.teacherZoomImg {
  position: absolute;
  left: 20%;
  top: 24%;
  animation: floatAnimation 5s ease-in-out infinite alternate;
}

.zoomImg {
  position: absolute;
  left: 10%;
  top: 24%;
  animation: floatAnimation 4s ease-in-out infinite alternate;
}

.flip-horizontal {
  transform: scaleX(-1);
}

@keyframes floatY {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(var(--translate-y));
  }
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
.float {
  animation: float 3s ease-in-out infinite;
}

.bubble {
  animation: floatY var(--animation-duration-y) ease-in-out infinite;
  will-change: transform;
}

.grainytop /* resulting css */ {
  width: 100vw;
  height: 450px;
  filter: contrast(100%) brightness(100%);
  background: linear-gradient(
      180deg,
      rgba(255, 22, 177, 0.322),
      rgba(255, 247, 235, 1)
    ),
    url("data:image/svg+xml,%3Csvg viewBox='0 0 119 119' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='5.43' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
}
.grainybotmenu /* resulting css */ {
  filter: contrast(100%) brightness(100%);
  background: linear-gradient(
      90deg,
      rgba(13, 154, 248, 0.222),
      rgba(80, 218, 246, 1)
    ),
    url("data:image/svg+xml,%3Csvg viewBox='0 0 119 119' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='5.43' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
}

.frost-effect {
  backdrop-filter: blur(1px);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0),
    rgb(255, 246, 222, 0.7),
    rgb(255, 246, 222, 1)
  );
}
